.mission {
    background-image: url('./../../../../public/pictures/mission/mission-page-background.webp');
    min-height: 800px;
    background-size: 2650px;  
}

.mission_container {
    padding-top: 155px;
    width: 66%;
    margin: 0 auto;
    padding-bottom: 200px;
}

.mission_title {
    text-align: left;
    font-family: barlow-medium,barlow,sans-serif;
    font-size: 44px;
    letter-spacing: 2px;
    font-weight: 600;
    margin-bottom: 80px;
}

.white_box_image {
    height: 80vw;
    width: 100%;


}

.mission_info_container {

    position: relative;
    text-align: center;

}

.mission_info {
    font-family: barlow-medium,barlow,sans-serif;
    font-size: 2.5vw;
    width: 80%;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@media only screen and (max-width: 1260px) {

 
}

@media only screen and (max-width: 860px) {
 
    .mission_container {
        min-width: 400px;
    }

}