#sidebar .sidebar_page.collapsed{
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgb(0, 0, 0);
    z-index: 999;
    opacity: 40%;
    transition: 0.3s ease-in-out;
    display: flex;
}
#sidebar .sidebar_page{
    display: none;
    opacity: 0%;
    transition: 0.3s ease-in-out;
   
}




#sidebar .sidebar_container{
    position: fixed;
    z-index: 999;
    width: 400px;
    height: 100%;
    background-color: white;
    top: 0;
    left: 0;
    visibility: visible;
    transition: 0.3s ease-in-out;
    white-space: nowrap;
    opacity: 100%;
}
#sidebar .sidebar_container.collapsed{
    visibility: hidden;
    width: 0px;
    opacity: 0;
    transition:  0.3s ease-in-out;
    
    
}

#sidebar .menu_list_container{
    
    margin-top: 45px;
    
}

#sidebar .nav_list_item{
    padding-left: 20px;
    padding-top:15px;
    padding-bottom:15px;
    text-align: left;
    text-decoration: none;
    list-style-type: none;
    font-family:  barlow, sans-serif;
    color: #000000;
    font-size: 16px;
    

}
#sidebar .nav_list_item:hover{
    background-color: rgb(240, 241, 249);
    
}

#sidebar .close_icon_container{
    margin-top: 25px;
    text-align: right;
    padding-right: 37px;
    font-size: 16px;
}
#sidebar .close_icon:hover{
    color: rgb(99, 107, 163);
    cursor: pointer;
}

#sidebar .sb_nav_list_container{
    display: none;
}


@media only screen and (max-width: 535px) {
    #sidebar .sb_nav_list_container {
        display: block;
    }

}