.send{
    background-image: url('../../../../public/pictures/send/send-background.webp');
    padding-top:80px;
    padding-bottom: 70px;
}


.reasons_container{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
   
}


.list_item{
    list-style-type: none;
    padding:25px;
    font-weight: 400;
    color: white;
    font-size: 22px;

    position: relative;
    text-align: center;
}

.title {
    font-size: 24px;
    font-weight: 500;
    color: black;
    width: 290px;
    
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.list_item_image {
    width: 330px;
}

.list_item_text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.share_title{
    margin-top: 70px;
    margin-bottom: 40px;
    text-align: center;
    font-family: barlow-medium, barlow, sans-serif;
    font-size: 32px;
    font-weight: 500;

    
}
.share_link{
    font-family: barlow-medium, barlow, sans-serif;
    font-size: 21px;
    font-style: normal;
    font-weight: 400;
    width:auto;
    position: relative;
    text-align: center;
    margin-bottom: 30px;

}
.share_link_item{
    text-decoration: none;    
}
.share_link_item:visited{
    text-decoration: none;
    color: black;
}
.share_link_item:hover{
    text-decoration:underline;

}


.wobly_box{
    width:450px;
}

.google_drive_text{
    font-size: 50px;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.google {
    color: rgb(70, 70, 70);
    font-weight: 600;
}
.drive {
    color: rgb(88, 88, 88);
    font-weight: 400;
}
.share_info{
    font-family: barlow-medium, barlow, sans-serif;
    font-size: 24px;
    text-align: center;
}
.mail_link{
    text-decoration: none;
    color: rgb(137, 68, 146);
}
.mail_link:hover{
    text-decoration: underline;
}
.mail_link:visited{
    text-decoration: none;
    color: black;
}

@media only screen and (max-width: 1140px) {
    .reasons_container{
        grid-template-columns: 1fr 1fr;
       
    }

}

@media only screen and (max-width: 750px) {
    .reasons_container{
        grid-template-columns: 1fr;
    }

    .wobly_box{
        width:300px;
    }

    .google_drive_text{
        font-size: 38px;
    }


}
@media only screen and (max-width: 500px) {
    .list_item_image {
        width: 250px;
    }
    .list_item {
        padding: 15px;
        font-size:  18px;
    }
    .title{
        padding-bottom: 2rem ;
    }
}