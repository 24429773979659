.services {
    padding: 50px 0px;
    

    background-image: url('../../../../public/pictures/services/services-background.webp');
    background-size: 2200px ;
}


.info_block {
    width: 1050px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 0.6fr 1fr;
    margin-top:30px;
    margin-bottom: 60px;
    
}

.info_block_left_text {
    width: 1050px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 0.6fr;
    margin-top:30px;
    margin-bottom: 60px;
}
.info_container{

    margin-top:7%;
    margin-bottom: 10%;
  

    font-family: barlow-medium, barlow, sans-serif;
    font-size: 22px;
    text-align: left;

    
    position: relative;
    text-align: center;
    
}
.info_title {
    font-size: 24px;
}


.image_container {
    display:inline-flex;
    align-items:center;
    justify-content: flex-start;

}

.info {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
}
.info_list {
    text-align: left;
}

.image_item{
    text-align: unset;
    width: auto;
    height: 320px;
    object-fit: cover;
}