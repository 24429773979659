
.promo_banner{
    width:100%;
    height:auto;
    padding: 50px 80px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
}
.promo_banner_content {

    display: grid;
    width: 70%;
    height: auto;
    margin: 0 auto;
    grid-template-columns: 1fr 1.75fr;
}
.image_container{
    height: 500px;
}
.image {
    max-width: 400px;
    height: 100%;
    -moz-user-select: -moz-none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;
    user-select: none;
    caret-color: transparent;
}
.text_container{
    padding: 70px;
    text-align: left;
}

.title{
    font-size: 24px;
    font-weight: 600;
    padding-bottom: 15px;
}

.description {
    font-size: 16px;
    font-weight: 400;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    margin-bottom: 15px;

}
.button_container {
    margin-top: 10px;
}
.button_container a {
    text-decoration: none;
}


.go_to_button {
    padding: 10px 20px;
    border-radius: 5px;
    width: 120px;
    background-color: #222222;
    color: white;
    font-size: 16px;
    font-weight: 600;
    border: none;
    cursor: pointer;
    transition: 0.3s;
}
.go_to_button:hover {
    background-color: #333333;
}

@media only screen and (max-width: 1350px) {
    .promo_banner_content {
        width: 90%;
    }
}

@media only screen and (max-width: 1100px) {
    .promo_banner_content {
        grid-template-columns: auto 475px;
    }
    .image {
        max-width: 300px;
    }
    .text_container{
        padding: 30px;
    }
}

@media only screen and (max-width: 900px) {
    .promo_banner_content {
        grid-template-columns: 1fr;
    }
    .image_container{
        height: 500px;
    }
    .text_container{
        padding: 20px;
    }
}


@media only screen and (max-width: 550px) {
    .promo_banner {
        padding: 20px 50px;
        align-items: flex-start;
    }
    .image_container {
        height: 300px;
    }
    .text_container{
        padding: 10px;
    }

}

    