.card{
    height:520px;
    width: 300px;
    min-width: 300px;
    padding: 30px;
    padding-bottom: 0;

    border: 1.5px rgba(177, 174, 174, 0.432) solid;
}

.card_inner{
    height: 490px;
    width:100%;
   
    z-index: 900;
    position: relative;
    
    
   
}
.image_container{
    width:100%;
    height:430px;
    
}

.info_container{
    position: absolute;
    background-color: white;
    bottom: 0;
    z-index: 900;
    height:60px;
    width:100%;
    transition: ease-in-out 0.25s;
    color: black

}

.author_container{
    text-align: left;
    padding-top: 20px;
    font-size: 16px !important;
    font-family: 'Raleway', sans-serif;
    font-weight: bold;
}
.bookname_container{
    text-align: left;
    padding-top: 5px;
    font-size: 18px !important;
    font-family: "Sabon", serif !important;
    font-weight: 100;
    
}


.image_item{
    object-fit:fill;
    width:100%;
    height: 430px;

}


@media only screen and (max-width: 1840px) {
    .card{
        height:470px;
        min-width: 230px;
        max-width:230px;
        padding: 30px;
        padding-bottom: 0;
    
        border: 1.5px rgba(177, 174, 174, 0.432) solid;
    }
    .image_item{
    object-fit:fill;
    width:100%;
    height: 370px;

}
.image_container{
    width:100%;
    height:370px;
    
}
.card_inner{
    height: 430px;
    width:100%;
   
    z-index: 901;
    position: relative;
    
    
   
}

}