
.books{

    min-height: 100vh;
}

.cards_container_container{
    width: 100%;
}
.search_container{
    padding-top: 30px;
    width: 100%;
    height:50px;
 
    width: 1150px;
    margin: 0 auto;
    display: flex;
}
.inner_container{
    display: flex;
    margin: 0 auto;
}
.textbox{
    width: 500px;
    height:50px;
}
.dopdown{
    width:100px;
    height:50px;
    margin-left: 5px;
    margin-right: 5px;
    
}
.dopdown:hover{
    cursor: pointer;
}
.submit_butn{
    width:100px;
    height: 50px;
   
    background-color: white;
    border: .5px solid rgb(75, 74, 74);
    border-radius: 2px;
}
.submit_butn:hover{
    background-color: rgb(245, 241, 255);
    cursor: pointer;
    
}
.cards_container{

    display: flex;
    width: 1150px;
    flex-wrap: wrap;
    
    margin: 0 auto;
    column-gap: 24px;
    box-sizing: border-box;

    
}

.card{
    min-width: 200px;
    width: 200px;


    margin-top: 40px;
    margin-bottom: 50px;
    margin-left: 15px;
    margin-right: 15px;

}
.image_container{
    min-width: 200px;
    width: 200px;
    height: 300px;
}
.image_item{
    min-width: 200px;
    width: 200px;
    caret-color: transparent;
}
.info_container{
    text-align: left;
}
.author_container{
    font-family: "Raleway", sans-serif;
    font-size: 17px;
    font-weight: 700;
    line-height: 23px;
    padding-top: 25px;
}

.link{
    color: black;
}
.link:visited{
    text-decoration: none;
    color: black;

}
.bookname_container{
    font-family: "Sabon", serif;
    font-size: 22px;
    font-weight: 100;
    line-height: 26px;
    padding-top: 7px;
}

@media only screen and (max-width: 1160px) {
    .cards_container{

        width: 920px;

    
        
    }
    .search_container{
  
        width: 920px;

    }
}
@media only screen and (max-width: 930px) {
    .cards_container{

        width: 690px;

    
        
    }
    .search_container{
  
        width: 690px;

    }
    .textbox{
        width: 300px;
        
    }
    .dopdown{
        width:90px;
       
        
    }
 
    .submit_butn{
        width:90px;
       
    }
}
@media only screen and (max-width: 700px) {
    .cards_container{

        width: 460px;

    }
    .search_container{
  
        width: 460px;

    }
    .textbox{
        width: 240px;
        
    }
    .dopdown{
        width:90px;
       
        
    }
 
    .submit_butn{
        width:90px;
       
    }
}
@media only screen and (max-width: 470px) {

    .card{
  
    margin-left: 8px;
    margin-right: 8px;

    }
    .cards_container{

        width: 100%;

    }
}