.coming_soon{
    color: #FFFFFF;

    text-shadow: 4px 3px 0px #7A7A7A;
    font-size: 100px;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    text-shadow: 60px;
    height:780px;
    width:100%;
    background-image: url('../../../public/pictures/send/send-background.webp');
    padding-top: 100px;
}