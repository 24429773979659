.team {
    background: url('../../../../public/pictures/team/team-background.webp');

    min-height: 900px;
    background-size: 100vw;
    padding-bottom: 100px;

}

.team_container {

    margin: 0 auto;
    width: 700px;
}
.team_title {
    padding-top: 1rem;
    font-size: 1.7rem;
    font-weight: 600;
    margin-bottom: 3rem;
    text-align: center;
}
.team_content {
    display:grid;
}
.teamMember_content{
    animation: fadein 1s;
    transition: 0.5s;

}
@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

.book_thumbail_image_container {
    margin-top: 0.2rem;
    width: 100%;
    height: 100%;

}
.book_thumbail_image {
    width: 6rem;
}

.teamMemberItem {
    display: grid;
    grid-template-columns: 1fr 3fr;
    text-align: left;


}
.teamMemberItem_content_header{
    scroll-margin: 100px;
}
.teamMemberItem_content_header:hover {
    cursor: pointer;
}
.teamMember_description:hover {
    cursor: pointer;
}

.teamMember_image {
    width: 7rem;
    height: 7rem;
    object-fit: cover;
    border-radius: 50%;
    

}
.teamMemberItem_content_container { 
    padding: 0rem 1.4rem 0 1.4rem;
}
.teamMember_name {
    font-weight: 600;
    font-size: 1.2rem;
    line-height: 1rem;
}
.row_gap{
    margin-bottom: 1rem;
}
.teamMember_position {
    font-size: 0.8rem;
    font-weight: 600
}
.teamMember_description {
    scroll-timeline: 100px;
    scroll-margin: 100px;
    font-size: 0.9rem;
    font-weight: 600;
    hyphens: auto;
    margin-bottom: 0.5rem;
}

.link_icon {
    width: 1.2rem;
    height: 1.2rem;
    padding-top: 0.5rem;
    margin-right: 0.5rem;

}
.teamMember_links {
    display:flow-root;
}
.teamMember_link {
    display: flex;
    align-items: center;

}

.link {
    color: black;
    text-decoration: none;
    font-weight: 600;
    align-items: center;
    margin-bottom: 0.1rem;

}
.link:visited{
    text-decoration: none;

}
.former_members_label{
    padding-top: 5rem;
    font-size: 1.7rem;
    font-weight: 600;
    margin-bottom: 5rem;
    text-align: center;
    cursor: pointer;
}

@media only screen and (max-width: 750px) {
    .team_container {
        width: 90%;
    }
}