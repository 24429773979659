
.contact_container_container {
    background-color: #6fc3db;
    min-height: 600px;
    
}
.contact_container{

    display: grid;
    padding-top: 100px;
    padding-bottom: 100px;
    grid-template-columns: auto 1fr;
    width: auto;
    padding-left: 5rem;
    transition: all .5s ease-in-out;
    
}
.contact_header{
    font-size: 5rem;
    font-weight: 700;
    color: white;
    text-align: left;
    
    letter-spacing: 0.5rem;
    width: 333px;
    max-width:333px;


}
.contact_content {
}

.logo_section_container_container {
    padding-top: 10rem;
    padding-bottom: 10rem;
}
.logo_section_container {
    width: 800px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
}
.logo_container {
}

.logo_image {
    width: 20rem;
    caret-color: transparent;
}

.log_info_container {
    padding-top: 3.2rem;
    background-color: rgb(255, 255, 255);
    text-align: left;

}

.logo_info_header {
    font-size: 2rem;
    font-weight: 700;
    letter-spacing: 0.2rem;

}

.logo_info_header_sub {
    font-size: 2.3rem;
    font-weight: 200;

}

.logo_info_text {
    font-size: 1rem;
    font-weight: 500;
    letter-spacing: 0.1rem;
    
    line-height: 1.7rem;
    color: rgba(0, 0, 0, 0.80);

    margin-top: 1.8rem;
}
.logo_info_link{
    font-size: 1rem;
    font-weight: 500;
    letter-spacing: 0.1rem;
    line-height: 1.7rem;
    color: rgba(0, 0, 0, 0.80);

    margin-top: 1.8rem;
}

.email_link_item{
    text-decoration: none;
    color: #ff00f7e8;

}

.footer_container_container{

    background-image: url('../../../../public/pictures/contact/background.webp');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;

    text-align: left;
    padding-left: 5rem;
    padding-top: 15rem;
    padding-bottom: 9rem;
}

.footer_description_text {
    font-weight: 600;
    font-size: 1.2rem;
    width: 35rem;
}
.footer_gap {
    margin-top: 2rem;
}
.footer_header {
    font-size: 8rem;
    letter-spacing: 0.5rem;
    font-weight: 700;
    color: white;
}



@media only screen and (max-width: 1100px) {
    .logo_section_container {
        grid-template-columns: 1fr;
        width: auto;
    }
   
    .log_info_container {
        text-align: center;
    
    }
    .contact_container{
        width: auto
    }
    
    .footer_container{
        width: 80%;
    }
}


@media only screen and (max-width: 770px) {
    .contact_container{
        grid-template-columns: 1fr 
    }
    .contact_content {
        margin-top: 2rem;
    }
    .footer_header {
        margin-top: 2rem;
        font-size: 15vw;
        text-align: right;
    }
    .footer_description_text {
        width: auto
    }
}