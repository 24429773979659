.contactForm {
    padding-top: 1.5rem;
    text-align: left;
    font-size: 1.1rem;
    width: 18rem;
    

}

.sumbitted_message {
    text-align: center;
    padding-top: 8rem;
    font-size: 1.7rem;
    font-family: savoye LET;
    font-weight: 500;
    color: #0c84a6;
    font-style: italic;
}

.label {
    color: #061a20;
    font-weight: 500;
}
.input {
    margin-top: 0.4rem;
    outline: none;
    border: none;
    border-radius: none;
    width: 100%;
    height: 2rem;
    margin-bottom: 1rem;
    box-shadow: inset 0 0 10px #0000006b;
}


.message_input {
    resize: none;
    outline: none;
    border: none;
    border-radius: none;
    width: 100%;
    box-shadow: inset 0 0 10px #0000006b;
    margin-bottom: 1rem;
    margin-top: 0.4rem;
}

.submit_button {
    width: 100%;
    background-color: #14647b;
    height: 2.7rem;
    margin-top: 1.3rem;
    border-radius: 5px;
    text-align: center;
    line-height: 2.7rem;
    font-weight: 500;
    letter-spacing: 0.1rem;
    color: white;
    font-size: 1.3rem;
}
.submit_button:hover {
    background-color: #0c84a6;
    cursor: pointer;
}
:root {
    --duration: 5s;
  }
.error_message {
    color: rgb(148, 44, 44);
    font-size: 1rem;
    margin-top: 0.2rem;
    font-weight: 600;
    -moz-animation: inAndOut var(--duration) ease-in forwards;
    -webkit-animation: inAndOut var(--duration) ease-in forwards;
    animation: inAndOut var(--duration) ease-in forwards;

    transition: mex-height 2s;
}
@keyframes inAndOut {
    0% {
      opacity: 0;
    }
    10% {
      opacity: 1;
    }
    90% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  .error_hidden {
    max-height: 0;
    transition: max-height 10s;

  }

@media only screen and (max-width: 770px) {

    .sumbitted_message {
        padding-bottom: 100px;
    }
}