.publication{
    text-align: left;
    margin-left: 20px;
    margin-right: 20px;
    min-height: 700px;
}
.top_container{
    height:auto;
    width:100%;
    
    padding-bottom: 50px;
    margin-top:50px;
       
    display: grid;
    height: 100%;
    grid-template-columns: 1.2fr 1.8fr 1fr;
}




.thumbnail_container{
    text-align: center;
    height: 100%;
    
}
.image_item{
    object-fit:fill;
    width: 350px ;
    caret-color: transparent;
    
}




.book_main_container{
    height: 100%;
    
    margin-left: 20px;
    margin-right: calc(5%);
    margin-top: 0px;
    margin-bottom: 0px;
}
.title_author{
    font-size: 31px !important;
    line-height: 35px;
    font-family: 'Raleway', sans-serif;
    font-weight: 700;
}
.book_title{
    font-size: 37px !important;
    line-height: 41px;
    font-family: "Sabon", serif !important;
    font-weight: 100;
    margin-bottom: 20px;
}

.book_description{
    font-size: 16px;
    line-height: 22px;
    font-family: 'Raleway', sans-serif;
    font-weight: 400;
    margin-bottom: 5px;
}

.download_link{
    color: #6f76c7 !important;
    display: block;
    float: left;
    
}
.download_link:visited{
    text-decoration: none;
    color: #6f76c7 !important;
}
.download_link:hover{
    text-decoration:none;
}

.author_main_container{
    height: 100%;
    
}
.author_name{
    color: #6f76c7 !important;
    font-family: "Sabon", serif !important;
    font-weight: 100;
    font-size: 21px !important;
    line-height: 21px;
    margin-bottom:20px;
}
.author_description{
    font-size: 14px;
    line-height: 22px;
    font-family: 'Raleway', sans-serif;
    font-weight: 400;

    
}
.author_link{
    font-size: 14px;
    line-height: 22px;
    font-family: 'Raleway', sans-serif;
    font-weight: 400;
    text-decoration: underline;
    color: #6f76c7 !important;
}
.author_link:hover{
    text-decoration: none;
    cursor: pointer;
}

.recomendations_text{
    text-align: left;
    font-weight: 700;
    font-size: 21px;
    font-family: sans-serif;
    
    padding-left: 50px;
    padding-top: 10px;
}
.donation_reminder_container{
    padding:30px 0% 
}   
.donation_reminder_box {
    background-color: #f5f5f5;
    border: 1px solid #e6e6e6;
    padding: 20px;
    box-sizing: border-box;
    margin-top: 20px;
    margin-bottom: 20px;
    border-radius: 5px;
    text-align: left;
}

.donation_reminder_link{
    font-size: 14px;
    line-height: 22px;
    font-family: 'Raleway', sans-serif;
    font-weight: 400;
    text-decoration: none;
    color: black
}
.donation_reminder_link:hover{
    text-decoration: underline;
    cursor: pointer;
}

@media only screen and (max-width: 1200px) {
    .top_container{
        grid-template-columns: 1.2fr 1.8fr;
        grid-template-areas: 
                "left right"
                "both both"; 
    }
    .author_main_container{
        margin-top: 20px;
        grid-area: both; 
    }
    
}


@media only screen and (max-width: 1000px) {
    .top_container{
        
        grid-template-columns: 1.2fr 1.8fr;
        grid-template-areas: 
                "left right"
                "both both"; 
    }

    .author_main_container{
        margin-top: 20px;
        grid-area: both; 
    }

    .donation_reminder_container{
        padding: 20px 0px;
    }
    
    
}

@media only screen and (max-width: 770px) {
    .top_container{
        display:inline-block;
       }

    .author_main_container{
        margin-top: 50px;
        
    }
    .book_main_container{
        margin-top:30px;
        margin-left: 0px;
        margin-right: 0px;
    }
    .image_item{
        object-fit:fill;
        width: 250px ;
        
    }
    .donation_reminder_box {
        padding: 15px;
    }
    
}