.Recomendations_inner{
    height: 500px;
    width: 100%;
    background-color: aqua;
}
.cards_container{
    display: flex;
    margin:30px;

}
.card_item{
     
}


@media only screen and (max-width: 1160px) {

}