.container{
    width: 100%;
    height:350px;
  
    background-image:url('https://spread-the-word.herokuapp.com/files/public/test7.png');
    
    /*background-image:url("https://librosdelasteroide.com/assets/images/pattern.png");
    */
    background-repeat: repeat;
    position: relative;;
}

.inner_container{
    height: 270px;
    width: 1150px;
    background-color: white;

    position:absolute;
    top: 50%;
    left: 50%;
    transform:translate(-50%, -50%);
}

.title_text{
    margin-top: 60px;
    color: black;
    font-family: "Raleway", sans-serif;
    font-size: 17px;
    text-decoration:solid;
    font-weight: 600;
}
.sub_title_text{
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    margin-top: 25px;
}
.submission_container{
    margin-top: 50px;
    width:100%;
    position:absolute;
    top: 50%;
    left: 50%;
    transform:translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    
}


.submission_inner_container{
    width: 500px;
    height:60px;
    outline: 1.5px solid rgb(173, 173, 173);
    margin-right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: ease-in-out 0.3s;
    
}
.submission_inner_container:focus-within{
    outline: 1.8px solid rgb(111, 119, 199);
    transition: ease-in-out 0.3s;
}


.textbox{
    width:450px;
    height: 30px;
    border-style:hidden;
    border-width:0px;
    border:none;
    font-size: 18px;
    font-family:  barlow, sans-serif;
    color: rgb(111, 119, 199);
    opacity: 100%;
}
.textbox:focus{
    
    outline: none;
}


.submit_butn{
    background-color: rgb(111, 119, 199);
    border:none;
    outline:none;
    width: 175px;
    height: 60px;
    color: white;
    font-size: 14px;
    font-family:  barlow, sans-serif;
    font-weight: 200;
}
.submit_butn:hover{
    outline: 1.5px solid rgb(111, 119, 199);
    background-color: white;
    color: rgb(111, 119, 199);
    transition: ease-in-out 0.2s;
    cursor: pointer;
}

@media only screen and (max-width: 700px)  {
    .submission_container{

        display: block;


    }
    .submission_inner_container{
        margin-top: 50px;
        margin-bottom: 20px;
       position: relative;
       padding-left: 30px;
       left:0;
       width: 100%;
        
    }
    .inner_container{
        height: 300px;
    }
    .container{
        height: 350px;
    }
    .submit_butn{
       margin-bottom:20px;
    }

}
@media only screen and (max-width: 1200px)  {
    
    .inner_container{
        width:100%;
    }
}