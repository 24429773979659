.news_articles {
    width: 100%;
    padding: 0rem 7rem 4rem 7rem;
    box-sizing: border-box;
}

.news_articles_container {
    display: flex;
    flex-direction: row;
    justify-content: start;
    column-gap: 5rem;
    row-gap: 5rem;
    width: 100%;
    flex-wrap: wrap;
}

@media only screen and (max-width: 1500px) {
    .news_articles {
        padding: 0 5rem 4rem 5rem;
    }
    .news_articles_container {
        column-gap: 4rem;
        row-gap: 4rem;
    }
}


@media only screen and (max-width: 1000px) { 
    .news_articles {
        padding: 0rem 2rem 4rem 2rem ;
    }
    .news_articles_container {
        column-gap: 2rem;
        row-gap: 2rem;
    }
}

@media only screen and (max-width: 1000px) {  
    .news_articles_container {
        justify-content: center;
    }
}