/* .home {
    background-image: url('../../../../public/pictures/home/homepage-background.webp');
    background-size: 2700px;
    padding-bottom: 120px;
    min-height: 600px;


} */

.banner_add {
    width: 100%;
}



.card_list_title{
    text-align: center;
    font-weight: 500;
    font-size: 36px;
    font-family: sans-serif;

    padding-top: 30px;
    padding-bottom: 30px;
}


#Flickity{
    width: 100%;
}