.header{
    background-color: white;
}
.header_container{
    width: 100%;
    height: 121px;
    
}
.header_inner_container{
    width: 100%;
    height: 70px;
    
    padding-top: 25px;
    padding-bottom:25px;
    min-width: 230px;
    white-space: nowrap;
    display: flex;
    align-items: center;
   
}
.grid_container{
   
    display: grid;
    height: 100%;
    grid-template-columns: 0.65fr 1.2fr 2fr 4fr;
  
}
.bars_container{
    width: 50px;
    height: 30px;
    padding-top: 10px;;
    display: flex;
    margin-left: 35px;
    margin-top: 40px;
}

.bars_icon{
    height: 30px;;
}
.bars_icon:hover{
    cursor:pointer;
}
.header_logo_container{
    
    height: 70px;
    width:110px;

    grid-column-start: 0;
    grid-column-end: 0;
    padding-left: 10%;
    
}
.logo_item{
    margin-top:5px;
    width:110px;
    -moz-user-select: -moz-none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;
    user-select: none;
    caret-color: transparent;
}

.logo_texts{
    text-align: left;
    text-decoration: none;
}
.logo_texts:visited{
    color: inherit;
}
.page_title_container{
    padding-top: 12px;
    white-space: nowrap;
    overflow: hidden;
    
}
.page_title_text{
    font-size: 29px;
    font-family:  barlow, sans-serif;
    line-height:1.2em;
    letter-spacing:0.05em;
    
}

.page_slogan_container{
    padding-top: 6px;
    
}
.page_slogan_text{
    font-size: 11px;
    line-height:1.1em;
    letter-spacing:0.23em;
    font-family:  barlow, sans-serif;
}



.nav_list_container{
    width: 100%;
    height: 100%;
    
    display: flex;
    text-align: center;
    align-items: center;
    margin-left: 0px;
}

.nav_list_item{
    list-style-type: none;
    margin-left: 20px;
    margin-right: 20px;
    white-space: nowrap;
    color: black;
    font-family: barlow, sans-serif
}
.nav_list_item:hover{
    color: rgb(92, 92, 92);
    
}


@media only screen and (min-width:980px){
    .header_logo_container{
        margin-left: 5%;

    }
    
    
}
@media only screen and (max-width: 835px) {
    .header_inner_container{
      display: none;
       
    }
    .nav_list_container{
        margin-left: 0px;
  

    }
    .header_logo_container{
        
    }
    .nav_list_item{
        align-items: flex-end;
        text-align: right;
        list-style-type: none;
        margin-left: 10px;
        margin-right: 10px;
        white-space: nowrap;
    }
}

@media only screen and (max-width: 650px) {
    .header_logo_container{
        display: none;
    }
    .nav_list_container{
        margin-left: 0px;
  

    }
}



@media only screen and (max-width: 535px) {
    .nav_list_container{
        display: none;
    }
}