.footer_container{
    height: 470px;
    width:100%;
    /* background-image: url("/public/pictures/footer/cat-footer.webp"); */

    background-size: cover;
    background-position: center top;
    padding-top:50px;
    padding-bottom: 90px;
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
}
.footer_container.cats_background {
    background-image: url("/public/pictures/footer/cat-footer.webp");

}


.follow_container{
    font-family: barlow-medium,barlow,sans-serif;
    font-size: 30px;
    text-align: center;
    padding-top: 45px;

    line-height: 70px;
   font-weight: 100;
    
}

.bar_container{
    padding-top: 5px;
    height: 10px;
   
}

.media_container{
    height: 275px;
    width: 100%;
    
    margin: 0 auto;
    align-items: center;

}

.media_grid_container{
    padding-top: 60px;
    width: 100%;
    display: grid;
   
    grid-template-columns: 1fr 1fr 1fr;
    height: 100px;

}

.grid_item {
    width: 100%;
    justify-content: center;
    align-items: center;
    
  
}
.grid_item_title{
    font-size: 17px;
    color: rgb(21,93,233);
    font-family: normal normal normal 17px/1.4em barlow-medium,barlow,sans-serif;
    padding-bottom: 45px;
}

.social_media_list{
    display: inline-block;
    text-decoration: none;
 
}
.social_media_list_item{
    display: inline-block;
    text-decoration: none;
    padding-left: 15px;
    padding-right: 15px;
    caret-color: transparent;

    
}
.social_media_item_image{
    height:60px;
    width: 60px;
}

.form_title{
  
    font-family: barlow-medium,barlow,sans-serif;
    font-size: 24px;
    text-align: center;
    padding-top: 50px;
    line-height: 70px;
    font-weight: 100;

}

.form_container{
    width:500px;
    margin: 0 auto;
    display:  grid;
    grid-template-columns: 2fr 1fr;
    margin-bottom: 35px;
}


.form_item{
    width: 100%;
    height:45px;
    line-height: 50px;
    background: transparent;
    text-decoration: none;
    border: 1px solid black;
    border-radius: 2px 0px 0px 2px;
    padding-left: 15px;
}
.form_item:focus{
    outline:none;
    -webkit-box-shadow:inset 0px 0px 0px 1px #000;
    -moz-box-shadow:inset 0px 0px 0px 1px #000;
    box-shadow:inset 0px 0px 0px 1px #000;
}
.form_button{
    width: 100%;
    height:49px;
    
    font-size: 20px;
    text-decoration: none;
    background-color: black;
    border: 1px solid black;
    border-radius: 0px 2px 2px 0px;
    border: none;    
    color: white;
}

.form_button:hover{
    background-color: rgb(150, 131, 151);
    cursor: pointer;
}


.email_link{
    text-decoration: none;
    color: black;
}
.email_link:visited{
    text-decoration: none;
}

.copyright_container{

    width:100%;
    margin: 0 auto;
    text-align: center;
    padding-top: 70px;
    padding-bottom:20px;
    height: auto;
    font-size:24px;
    word-spacing: 2px;
    font-family: barlow-medium,barlow,sans-serif;
}


@media only screen and (min-width:980px){
    

    
    
}


@media only screen and (max-width:980px){
    .grid_item{
        width: auto;
    }
    
    
}

@media only screen and (max-width:720px){
    .footer_container{
        height: auto;
    }
    .media_grid_container{
        display:inline-block;
        width:100%;
        height:auto;
        padding-top: 0px;
    }
    .grid_item{
        width: 100%;
    }
    
    .copyright_container{
        margin-top: 60px;

    }
    .grid_item_title{
        padding:0;
        margin-bottom: 20px;
        margin-top: 20px;
    }
}
@media only screen and (max-width:520px){
    .form_container{
  
        width: 100%;
    }
}

@media only screen and (max-width:400px){
    .social_media_list_item{
        padding-left: 7px;
        padding-right: 7px;    
    }
    .social_media_item_image{
        height:40px;
        width: 40px;
    }
}    